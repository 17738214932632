import { AiOutlineClose } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import "moment-timezone";
import PrePaidMenuEmail from "./PrePaidMenuEmail";
import CustomButton from "../custom-button";
import { CustomDropdown } from "../custom-dropdown";
import "./serviceType.scss";
import { useEffect, useState } from "react";
import {
    emptyCart,
    setServiceData,
    setMenuPayType,
    setPrepaidMaxProduct,
    setPrepaidEmail,
} from "../../store/actions/ProductActions";
import { connect } from "react-redux";
import {
    getDates,
    isPreOrderActive,
    makeTimeSlots,
    weekday,
} from "../../common/utils";
import { format } from "date-fns";
import constants from "../../constants/constants";
import { apiService } from "../../services";
import CustomModal from "../custom-modal";

export const MenuSelection = (props) => {
    const navigate = useNavigate();
    const { serviceType, siteId, zoneId, outletId } = useParams();
    const redirectUrl = zoneId
        ? `/${constants.ROUTES.SITE}/${siteId}/${constants.ROUTES.ZONE}/${zoneId}/${constants.ROUTES.OUTLET}/${outletId}/${constants.ROUTES.SERVICE_TYPE}/${serviceType}`
        : siteId
        ? `/${constants.ROUTES.SITE}/${siteId}/${constants.ROUTES.OUTLET}/${outletId}/${constants.ROUTES.SERVICE_TYPE}/${serviceType}`
        : `/${constants.ROUTES.OUTLET}/${outletId}/${constants.ROUTES.SERVICE_TYPE}/${serviceType}`;
    const [daysList, setDayList] = useState([]);
    const [slotList, setSlotList] = useState([]);
    const { selectedMenu, setPrepaidMaxProduct, setPrepaidEmail, emptyCart } =
        props;
    const [selectedDay, setSelectedDay] = useState("");
    const [selectedTime, setSelectedTime] = useState(null);
    const [loading, setLoading] = useState(true);
    const [filledSlots, setFilledSlots] = useState([]);
    const [showPrepaidMenuEmail, setShowPrepaidMenuEmail] = useState(false);

    useEffect(() => {
        if (
            Object.keys(selectedMenu).length &&
            isPreOrderActive(props.outletTimezone, selectedMenu.menu.preorder)
        ) {
            setLoading(true);
            apiService
                .getAllFilledSlots(
                    outletId,
                    selectedMenu.menu.menuRef._id,
                    serviceType,
                )
                .then((response) => {
                    setFilledSlots(response);
                    fillDays();
                })
                .catch((err) => {
                    setLoading(false);
                    fillDays();
                    console.log("No Slots");
                });
        }
    }, [selectedMenu]);

    useEffect(() => {
        if (selectedDay) {
            const preOrder = selectedMenu.menu.preorder;
            const slotDuration = preOrder.slotDuration;
            const preOrderAcceptedBefore = preOrder.cutOff;
            let slotsOfDay =
                preOrder.timings[weekday[selectedDay.getDay()]].times;
            if (preOrder.oftenType === "SINGLE_DATE") {
                slotsOfDay = preOrder.preOrderRunTime;
            }
            const timeArray = makeTimeSlots(
                props.siteData,
                filledSlots,
                selectedDay,
                slotsOfDay,
                props.outletTimezone,
                slotDuration,
                preOrderAcceptedBefore,
                0,
            );
            setSlotList(timeArray);
            setSelectedTime(null);
        }
    }, [selectedDay]);

    const fillDays = () => {
        const dayArray = [];
        let days = getDates(
            props.outletTimezone,
            selectedMenu.menu.preorder,
            selectedMenu.menu.preorder.bookingAcceptedBefore,
            selectedMenu.menu.preorder.timings,
            selectedMenu.menu.preorder.cutOff,
        );

        for (let i = 0; i < days.length; i++) {
            dayArray.push({
                label: format(days[i], "EEE MMM dd yyyy"),
                value: days[i],
            });
        }
        setDayList(dayArray);
        setSelectedDay("");
        setSlotList([]);
        setLoading(false);
    };

    const handleNavigate = () => {
        const [hours, minutes] = selectedTime.split(":");
        let datetimeWithZone = moment(selectedDay);
        datetimeWithZone = datetimeWithZone.hours(Number(hours)).minutes(Number(minutes)).seconds(0);
        datetimeWithZone = datetimeWithZone.tz(props.outletTimezone);
        const newServiceData = {
            ...props.service,
            serviceType: serviceType,
            preOrder: true,
            deliveryDate: datetimeWithZone,
            minOrder: props.minOrder,
            deliveryFee:
                serviceType === "offsiteDelivery" ||
                serviceType === "onsiteDelivery"
                    ? props.deliveryFee
                    : 0,
            serviceTypeDisplayName: props.service.displayName,
            maxOrdersPerSlot: selectedMenu.menu.preorder.maxOrdersPerSlot,
            maxProductsPerSlot: selectedMenu.menu.preorder.maxProductsPerSlot,
            menuRef: selectedMenu,
            serviceSelectionTime: new Date(),
            tableNumber:
                serviceType === "onsiteDelivery"
                    ? props.service.tableNumber
                    : null,
            postCode:
                serviceType === "offsiteDelivery"
                    ? props.service.postCode
                    : null,
            locationIdentifier: props.service.locationIdentifier,
            notificationBefore: props.service.notificationBefore,
            vendorZoneName: props.vendorZoneName,
        };
        props.setServiceData(newServiceData);
        // props.emptyCart();

        if (selectedMenu.menu.menuRef.menuPayType === "prepaid") {
            setShowPrepaidMenuEmail(true);
            props.setMenuPayType(selectedMenu.menu.menuRef.menuPayType);
        } else {
            setPrepaidMaxProduct(null);
            setPrepaidEmail(null);
            if (props.menuPayType === "prepaid") emptyCart();
            props.setMenuPayType(selectedMenu.menu.menuRef.menuPayType);
            navigate(`${redirectUrl}/menus/${selectedMenu.menu.menuRef._id}`);
        }
    };

    return (
        <>
            <div className="action-header box-container">
                <h3>Select Pre Order Timeslot</h3>
                <AiOutlineClose onClick={props.onRequestClose} size={30} />
            </div>
            <div className="action-separator"></div>
            {/* {loading ? <ButtonLoader /> : null} */}
            <div className="box-container">
                {!Object.keys(selectedMenu).length ||
                !isPreOrderActive(
                    props.outletTimezone,
                    selectedMenu.menu.preorder,
                ) ? (
                    <p> Pre order is not active yet!</p>
                ) : (
                    <>
                        <h3>Select Day</h3>
                        <CustomDropdown
                            options={daysList}
                            onSelect={setSelectedDay}
                            value={
                                selectedDay ? selectedDay.toDateString() : ""
                            }
                            placeHolder={"Select Day"}
                            className="menu-select-box"
                        />
                        <h3>Select Time</h3>
                        <CustomDropdown
                            noOptionLabel={"No slots available"}
                            options={slotList}
                            onSelect={setSelectedTime}
                            value={
                                slotList.find((v) => v.value === selectedTime)
                                    ?.label
                            }
                            placeHolder={"Select Time"}
                            className="menu-select-box"
                        />
                        <div className="menu-confirm">
                            <CustomButton
                                disabled={
                                    !Object.keys(selectedMenu).length ||
                                    !selectedDay ||
                                    !selectedTime
                                }
                                className="btn-block"
                                onClick={() => handleNavigate()}
                            >
                                Confirm
                            </CustomButton>
                        </div>
                    </>
                )}
            </div>
            <CustomModal
                isOpen={showPrepaidMenuEmail}
                onRequestClose={() => setShowPrepaidMenuEmail(false)}
                title="Pre Order"
            >
                <PrePaidMenuEmail
                    menu={selectedMenu.menu?.menuRef}
                    redirectUrl={redirectUrl}
                />
            </CustomModal>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        setServiceData: (service) => dispatch(setServiceData(service)),
        setMenuPayType: (type) => dispatch(setMenuPayType(type)),
        emptyCart: () => dispatch(emptyCart()),
        setPrepaidMaxProduct: (maxProduct) => {
            dispatch(setPrepaidMaxProduct(maxProduct));
        },
        setPrepaidEmail: (email) => {
            dispatch(setPrepaidEmail(email));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        siteData: state.siteData,
        menuPayType: state.menuPayType,
        outletTimezone: state.outletData ? state.outletData.timezone : null,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuSelection);
