import { useEffect, useState, Fragment, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { FormattedNumber } from "react-intl";
import constants from "../../constants/constants";
import "./YourOrder.scss";
import { Loader } from "../custom-loader";
import { apiService } from "../../services";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { FaStarHalfAlt, FaStar, FaRegStar } from "react-icons/fa";
import {
    getVendorTZDate,
    getVendorTZTime,
    getVendorTZName,
    notify,
    orderContainerColor,
} from "../../common/utils";
import CustomButton from "../custom-button";
import { connect } from "react-redux";
import jwtService from "../../services/jwtService";
import ReactTooltip from "react-tooltip";
import { getImageUrl } from "../../common/utils";
import apiConsts from "../../constants/apiConstants";
import { io } from "socket.io-client";

const labelByPreorderService = {
    onsiteDelivery: "Delivery",
    offsiteDelivery: "Delivery",
    onsiteCollection: "Pickup",
    onsiteExpress: "Pickup",
    offsiteCollection: "Pickup",
};

const Feedback = ({ selectedRating }) => {
    let positiveRating = [],
        halfRating = [],
        negativeRating = [];
    if (Math.ceil(selectedRating) !== Math.floor(selectedRating)) {
        positiveRating = Array(Math.floor(selectedRating)).fill(1);
        halfRating = Array(1).fill(1);
        negativeRating = Array(4 - Math.floor(selectedRating)).fill(1);
    } else {
        positiveRating = Array(selectedRating).fill(1);
        negativeRating = Array(5 - selectedRating).fill(1);
    }
    return (
        <div className="feedback-input-row">
            <div className="rating-group">
                <span data-tip={selectedRating}>
                    {positiveRating.map(() => {
                        return <FaStar size={constants.ICON_SIZE} />;
                    })}
                    {halfRating.map(() => {
                        return <FaStarHalfAlt size={constants.ICON_SIZE} />;
                    })}
                    {negativeRating.map(() => {
                        return <FaRegStar size={constants.ICON_SIZE} />;
                    })}
                </span>
                <ReactTooltip />
            </div>
        </div>
    );
};

const RenderPrice = ({ price, currency }) => {
    return (
        <FormattedNumber
            value={price}
            style={`currency`}
            currencyDisplay="narrowSymbol"
            minimumFractionDigits={2}
            maximumFractionDigits={2}
            currency={currency || "GBP"}
        />
    );
};

function YourOrder({ user }) {
    const navigate = useNavigate();
    const params = useParams();
    const orderId = params.orderId;
    const location = useLocation();
    const [orderDetails, setOrderDetails] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [orderStatus, setOrderStatus] = useState("");
    const [overallFeedbackRating, setOverallFeedbackRating] = useState(null);
    const [refresh, setRefresh] = useState(true);

    function onSocketEvent(data) {
        setRefresh(true);
    }

    useEffect(() => {
        const socket = io(String(apiConsts.socketURL), {
            path: "/io",
            transports: ["websocket", "polling"],
            retries: 3,
        });

        socket.on(`web:orders:${orderDetails?._id}`, onSocketEvent);

        return () => {
            if (socket) {
                socket.disconnect();
            }
        };
    }, [orderDetails?._id]);

    useEffect(() => {
        if (orderId && refresh) {
            setLoading(true);
            apiService
                .getOrder(orderId)
                .then((res) => {
                    setOrderDetails(res);
                    if (user && jwtService.getAccessToken()) {
                        apiService
                            .getFeedbackByOrder(orderId)
                            .then((feedback) => {
                                if (res.serviceType === "onsiteExpress") {
                                    setOverallFeedbackRating(
                                        ((feedback?.recommendedRating || 0) +
                                            (feedback?.eventExperienceRating ||
                                                0) +
                                            (feedback?.speedRating || 0) +
                                            (feedback?.easeOfUseRating || 0)) /
                                            4,
                                    );
                                } else {
                                    setOverallFeedbackRating(
                                        (feedback.presentationRating +
                                            feedback.qualityRating +
                                            feedback.serviceRating +
                                            feedback.valueRating) /
                                            4,
                                    );
                                }
                                setLoading(false);
                            })
                            .catch(() => {
                                setOverallFeedbackRating(null);
                                setLoading(false);
                            });
                    } else setLoading(false);
                })
                .catch((err) => {
                    notify(err.message);
                    setLoading(false);
                });
            setRefresh(false);
        }
    }, [orderId, refresh]);

    const redirectUrl = (orderDetails) => {
        if (location.state && location.state.from === "order-list") {
            return navigate(-1);
        }

        const hasValidUser = orderDetails?.user?._id?.length > 0;
        const hasValidCartRef =
            orderDetails?.cartRef && orderDetails?.isMultiOrder === true;
        const hasValidSite = orderDetails?.site?.displayName?.length > 0;
        const hasValidSiteZones =
            orderDetails.siteZones?.siteZoneRef?.length > 0;
        const hasValidVendor = orderDetails?.vendor?._id?.length > 0;
        const isDirectAccess =
            sessionStorage.getItem("isDirectAccess") === "true";
        let url = "/";

        if (hasValidCartRef && !hasValidUser) {
            url = `/multi/${constants.ROUTES.ORDER}/${orderDetails.cartRef}`;
        } else if (hasValidSite && hasValidSiteZones && !isDirectAccess) {
            url = `/${constants.ROUTES.SITE}/${orderDetails.site.siteRef}/${constants.ROUTES.ZONE}/${orderDetails.siteZones.siteZoneRef}`;
        } else if (isDirectAccess && hasValidVendor) {
            if (hasValidSite && hasValidSiteZones) {
                url = `/${constants.ROUTES.SITE}/${orderDetails.site.siteRef}/${constants.ROUTES.ZONE}/${orderDetails.siteZones.siteZoneRef}/${constants.ROUTES.OUTLET}/${orderDetails.vendor._id}`;
            } else if (hasValidSite) {
                url = `/${constants.ROUTES.SITE}/${orderDetails.site.siteRef}/${constants.ROUTES.OUTLET}/${orderDetails.vendor._id}`;
            } else if (hasValidVendor) {
                url = `/${constants.ROUTES.OUTLET}/${orderDetails.vendor._id}`;
            }
        } else if (hasValidSite) {
            url = `/${constants.ROUTES.SITE}/${orderDetails.site.siteRef}/`;
        } else if (hasValidVendor) {
            url = `/${constants.ROUTES.OUTLET}/${orderDetails.vendor._id}`;
        }

        navigate(url, { replace: true });
    };

    useEffect(() => {
        let tempOrderStatus = "";
        if (orderDetails) {
            if (
                orderDetails.serviceType === "onsiteCollection" ||
                orderDetails.serviceType === "offsiteCollection" ||
                orderDetails.serviceType === "onsiteExpress" ||
                orderDetails.serviceType == "kiosk"
            ) {
                if (orderDetails.status === "PENDING") {
                    tempOrderStatus = "Order Placed";
                } else if (orderDetails.status === "ACCEPTED") {
                    tempOrderStatus = "Order Accepted";
                } else if (orderDetails.status === "READY FOR PICKUP") {
                    tempOrderStatus = "Ready to Collect";
                } else if (orderDetails.status === "COMPLETED") {
                    tempOrderStatus = "Collected";
                } else {
                    tempOrderStatus = orderDetails.status;
                }
            } else if (
                orderDetails.serviceType === "onsiteDelivery" ||
                orderDetails.serviceType === "offsiteDelivery"
            ) {
                if (orderDetails.status === "PENDING") {
                    tempOrderStatus = "Order Placed";
                } else if (orderDetails.status === "ACCEPTED") {
                    tempOrderStatus = "Order Accepted";
                } else if (orderDetails.status === "READY FOR PICKUP") {
                    tempOrderStatus = "On the way";
                } else if (orderDetails.status === "COMPLETED") {
                    tempOrderStatus = "Delivered";
                } else {
                    tempOrderStatus = orderDetails.status;
                }
            }
        }
        tempOrderStatus = tempOrderStatus?.toUpperCase();
        setOrderStatus(tempOrderStatus);
    }, [orderDetails]);

    return (
        <>
            {isLoading && <Loader />}
            <div className="your-order">
                <div className="box-container custom-header-wrapper header">
                    <div className="header-left">
                        {
                            <AiOutlineArrowLeft
                                size={35}
                                onClick={() => redirectUrl(orderDetails)}
                            />
                        }
                        <div className="header-title">
                            <span>Your Order</span>
                        </div>
                    </div>
                </div>
                <div className="separator separator-theme"></div>
                <div
                    className="your-order-container your-order-header-info box-container"
                    style={{
                        backgroundColor: orderContainerColor(
                            orderDetails.status,
                        ),
                    }}
                >
                    {orderDetails.serviceType === "onsiteExpress" && (
                        <h3 className="qr-code-message">
                            {orderDetails.vendorZoneName
                                ? `${orderDetails.vendorZoneName.toUpperCase()} | `
                                : ""}
                            {orderDetails.vendor?.displayName.toUpperCase()}
                        </h3>
                    )}
                    <h2 className="order-place-title">{orderStatus}</h2>
                    {orderDetails?.serviceType === "onsiteExpress" &&
                        orderDetails?.qrCodeUrl && (
                            <>
                                <h4 className="qr-code-message">
                                    Show QrCode at the Express Point
                                </h4>
                                <div className="express-qr-code">
                                    <img
                                        src={getImageUrl(
                                            orderDetails.qrCodeUrl,
                                        )}
                                        alt="QR code not found"
                                    />
                                </div>
                            </>
                        )}
                    <div className="your-order-order-info">
                        <h2 className="order-number">
                            Order ID: {orderDetails.orderNum}
                        </h2>
                        {orderDetails?.orderReferenceNumber ? (
                            <h2 className="order-number">
                                Order Reference:{" "}
                                {orderDetails.orderReferenceNumber}
                            </h2>
                        ) : (
                            <></>
                        )}
                        {/* <div className="order-customer-help-note" >
                  We have sent you an email with the link to re-access this
                  page
                </div> */}
                        {orderStatus !== "COMPLETED" ? (
                            <p className="m-0 text-center text-bold">
                                Please refresh the page to check the updated
                                status
                            </p>
                        ) : null}

                        <div className="feedback-row">
                            <CustomButton
                                className="feedback-button"
                                onClick={() => {
                                    setRefresh(true);
                                }}
                            >
                                Refresh
                            </CustomButton>
                        </div>
                    </div>

                    {orderDetails.earnPoint ? (
                        <div className="feedback-row">
                            <div>
                                {orderDetails.pointCreditedAt !== null
                                    ? "Loyalty Points Collected"
                                    : "Pending Loyalty Points"}
                            </div>
                            <div className="loyalty-order-text">
                                {orderDetails.earnPoint}
                            </div>
                        </div>
                    ) : null}

                    {user ? (
                        <div className="feedback-row">
                            <div>Any issues?</div>
                            <div>
                                <CustomButton
                                    className="feedback-button"
                                    onClick={() => {
                                        navigate(
                                            `/${constants.ROUTES.ORDER}/${orderId}/${constants.ROUTES.ISSUE}`,
                                        );
                                    }}
                                >
                                    Report Issue
                                </CustomButton>
                            </div>
                        </div>
                    ) : null}

                    {orderDetails.status === "COMPLETED" ? (
                        <div className="feedback-row">
                            <div>Your feedback</div>
                            {!isLoading ? (
                                <div>
                                    {!overallFeedbackRating ? (
                                        <CustomButton
                                            className="feedback-button"
                                            onClick={() => {
                                                navigate(
                                                    `/${constants.ROUTES.ORDER}/${orderId}/${constants.ROUTES.FEEDBACK}`,
                                                );
                                            }}
                                        >
                                            Rate Experience
                                        </CustomButton>
                                    ) : (
                                        <Feedback
                                            selectedRating={
                                                overallFeedbackRating
                                            }
                                        />
                                    )}
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                </div>
                <hr className="m-0" />
                <div className="your-order-container box-container">
                    <div className="mb-10">
                        <h3>Order Placed</h3>
                        <div>
                            {orderDetails.createdAt &&
                                format(
                                    parseISO(orderDetails.createdAt),
                                    "E MM/dd/yyyy hh:mm a",
                                )}
                        </div>
                        <h3>Order Type</h3>
                        <div className="order-container">
                            {orderDetails.serviceTypeDisplayName}{" "}
                            {orderDetails.preOrder ? " - Pre Order" : ""}
                            {orderDetails.serviceType === "onsiteDelivery"
                                ? orderDetails.tableNumber
                                    ? " " +
                                      (orderDetails.vendorZoneName
                                          ? `${
                                                orderDetails?.locationIdentifierLabel
                                                    ? orderDetails?.locationIdentifierLabel
                                                    : "Location"
                                            }: ` + orderDetails.vendorZoneName
                                          : "") +
                                      " - " +
                                      orderDetails.tableNumber
                                    : null
                                : null}
                            {orderDetails.preOrder &&
                            orderDetails.deliveryDate ? (
                                <>
                                    <div className="sub-section">
                                        <div className="row">
                                            <span className="column-two">
                                                {
                                                    labelByPreorderService[
                                                        orderDetails.serviceType
                                                    ]
                                                }{" "}
                                                Date
                                            </span>
                                            <span className="column-two flex-end">
                                                {getVendorTZDate(
                                                    orderDetails.deliveryDate,
                                                    orderDetails.vendor?.timezone
                                                )}
                                            </span>
                                        </div>
                                        <div className="row">
                                            <span className="column-two">
                                                {
                                                    labelByPreorderService[
                                                        orderDetails.serviceType
                                                    ]
                                                }{" "}
                                                Time
                                            </span>
                                            <span className="column-two flex-end">
                                                {getVendorTZTime(
                                                    orderDetails.deliveryDate,
                                                    orderDetails.vendor?.timezone
                                                )} {getVendorTZName(orderDetails.vendor?.timezone)}
                                            </span>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                            {orderDetails.serviceType === "offsiteDelivery" ? (
                                <>
                                    <div className="sub-section">
                                        <div className="row">
                                            <span className="column-two">
                                                Postal Code
                                            </span>
                                            <span className="column-two flex-end">
                                                {
                                                    orderDetails.deliveryAddress
                                                        .postalcode
                                                }
                                            </span>
                                        </div>
                                        <div className="row">
                                            <span className="column-two">
                                                Flat/House Number
                                            </span>
                                            <span className="column-two flex-end">
                                                {
                                                    orderDetails.deliveryAddress
                                                        .houseNumber
                                                }
                                            </span>
                                        </div>
                                        <div className="row">
                                            <span className="column-two">
                                                Street Name
                                            </span>
                                            <span className="column-two flex-end">
                                                {
                                                    orderDetails.deliveryAddress
                                                        .street
                                                }
                                            </span>
                                        </div>
                                        <div className="row">
                                            <span className="column-two">
                                                Special Instructions
                                            </span>
                                            <span className="column-two text-end flex-end">
                                                {
                                                    orderDetails.specialInstructions
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </div>
                        <h3>Order Summary</h3>
                        {(orderDetails.items || []).map((item) => (
                            <Fragment key={item._id}>
                                <div className="order-item-container">
                                    <div>
                                        {item.quantity + "x " + item.itemName}
                                        {Object.keys(item.variant).length
                                            ? " (" +
                                              item.variant.name.trim() +
                                              ")"
                                            : ""}
                                        <div className="item-modifiers">
                                            {item.modifiers.map((modifier) => (
                                                <span key={modifier._id}>
                                                    <div className="modifier-list">
                                                        {modifier.quantity +
                                                            "x " +
                                                            modifier.displayName}
                                                    </div>
                                                </span>
                                            ))}
                                        </div>
                                        {item?.specialInstructions
                                            ? item?.specialInstructions
                                            : null}
                                    </div>
                                    <div>
                                        {!orderDetails.prePaidOrder ? (
                                            <RenderPrice
                                                price={item.subTotal}
                                                currency={orderDetails.currency}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </Fragment>
                        ))}
                        <hr></hr>
                        {!orderDetails?.prePaidOrder ? (
                            <div className="order-payment-info">
                                <div className="text-bold">Sub Total</div>
                                <div className="text-bold">
                                    <RenderPrice
                                        price={orderDetails.subTotal}
                                        currency={orderDetails.currency}
                                    />
                                </div>
                            </div>
                        ) : null}
                        {!orderDetails?.prePaidOrder &&
                        orderDetails.itemBasedPromotion &&
                        orderDetails.itemBasedPromotion.length > 0 &&
                        orderDetails.itemBasedPromotion.some(
                            (promo) => promo.amount > 0,
                        ) ? (
                            orderDetails.itemBasedPromotion.map((itemPromo) => {
                                return (
                                    <div
                                        className="order-payment-info"
                                        key={itemPromo?.promotionRef || index}
                                    >
                                        <div>
                                            {itemPromo?.name || "Promotion"} (
                                            {itemPromo?.value + "%"})
                                        </div>
                                        <div>
                                            {itemPromo?.amount ? (
                                                <RenderPrice
                                                    price={-itemPromo?.amount}
                                                    currency={
                                                        orderDetails.currency
                                                    }
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <></>
                        )}
                        {!orderDetails?.prePaidOrder &&
                        orderDetails?.promotion &&
                        orderDetails?.promotion?.amount > 0 ? (
                            <div className="order-payment-info">
                                <div>
                                    {orderDetails?.promotion?.name ||
                                        "Promotion"}{" "}
                                    (
                                    {orderDetails.promotion?.savingType ===
                                    "percent" ? (
                                        orderDetails?.promotion?.value + " %"
                                    ) : (
                                        <RenderPrice
                                            price={
                                                orderDetails?.promotion?.value
                                            }
                                            currency={orderDetails.currency}
                                        />
                                    )}
                                    )
                                </div>
                                <div className="loyalty-used-order-amount">
                                    <RenderPrice
                                        price={-orderDetails?.promotion?.amount}
                                        currency={orderDetails.currency}
                                    />
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        {!orderDetails?.prePaidOrder &&
                        orderDetails.redeemValue &&
                        orderDetails.redeemValue !== 0 ? (
                            <div className="order-payment-info">
                                <div>Loyalty Points Redeemed</div>
                                <div className="loyalty-used-order-amount">
                                    <RenderPrice
                                        price={-orderDetails.redeemValue}
                                        currency={orderDetails.currency}
                                    />
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        {!orderDetails?.prePaidOrder &&
                        orderDetails.serviceCharge &&
                        orderDetails.serviceCharge !== 0 ? (
                            <div className="order-payment-info">
                                <div>Service Charge</div>
                                <div>
                                    <RenderPrice
                                        price={orderDetails.serviceCharge}
                                        currency={orderDetails.currency}
                                    />
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        {!orderDetails?.prePaidOrder &&
                        orderDetails.platformFee &&
                        orderDetails.platformFee !== 0 ? (
                            <div className="order-payment-info">
                                <div>Platform Fee</div>
                                <div>
                                    <RenderPrice
                                        price={orderDetails.platformFee}
                                        currency={orderDetails.currency}
                                    />
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        {!orderDetails?.prePaidOrder &&
                        orderDetails.deliveryFee &&
                        orderDetails.deliveryFee !== 0 ? (
                            <div className="order-payment-info">
                                <div>Delivery Fee</div>
                                <div>
                                    <RenderPrice
                                        price={orderDetails.deliveryFee}
                                        currency={orderDetails.currency}
                                    />
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        {!orderDetails?.prePaidOrder &&
                        orderDetails.tip &&
                        orderDetails.tip.amount ? (
                            <div className="order-payment-info">
                                <div>
                                    Tip (
                                    {orderDetails.tip.type === "Percentage" ? (
                                        orderDetails.tip.value + " %"
                                    ) : (
                                        <RenderPrice
                                            price={orderDetails.tip.value}
                                            currency={orderDetails.currency}
                                        />
                                    )}
                                    )
                                </div>
                                <div>
                                    <RenderPrice
                                        price={orderDetails.tip.amount}
                                        currency={orderDetails.currency}
                                    />
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        {orderDetails?.exclusiveTaxTotal ? (
                            <div className="order-payment-info text-bold">
                                <div>Exclusive Taxes</div>
                                <div>
                                    <RenderPrice
                                        price={orderDetails.exclusiveTaxTotal}
                                        currency={orderDetails.currency}
                                    />
                                </div>
                            </div>
                        ) : null}

                        {!orderDetails?.prePaidOrder ? (
                            <div className="order-payment-info text-bold">
                                <div>TOTAL</div>
                                <div>
                                    <RenderPrice
                                        price={orderDetails.totalAmount}
                                        currency={orderDetails.currency}
                                    />
                                </div>
                            </div>
                        ) : null}

                        {orderDetails?.inclusiveTaxTotal ? (
                            <div className="order-payment-info text-bold">
                                <div>Inclusive Taxes</div>
                                <div>
                                    <RenderPrice
                                        price={orderDetails.inclusiveTaxTotal}
                                        currency={orderDetails.currency}
                                    />
                                </div>
                            </div>
                        ) : null}
                        {!orderDetails?.prePaidOrder &&
                        orderDetails.vatAt5 &&
                        orderDetails.vatAt5 > 0 ? (
                            <div className="order-payment-info">
                                <div>VAT 5%</div>
                                <div>
                                    <RenderPrice
                                        price={orderDetails.vatAt5}
                                        currency={orderDetails.currency}
                                    />
                                </div>
                            </div>
                        ) : null}

                        {!orderDetails?.prePaidOrder &&
                        orderDetails.vatAt12_5 &&
                        orderDetails.vatAt12_5 > 0 ? (
                            <div className="order-payment-info">
                                <div>VAT 12.5%</div>
                                <div>
                                    <RenderPrice
                                        price={orderDetails.vatAt12_5}
                                        currency={orderDetails.currency}
                                    />
                                </div>
                            </div>
                        ) : null}

                        {!orderDetails?.prePaidOrder &&
                        orderDetails.vatAt20 &&
                        orderDetails.vatAt20 > 0 ? (
                            <div className="order-payment-info">
                                <div>VAT 20%</div>
                                <div>
                                    <RenderPrice
                                        price={orderDetails.vatAt20}
                                        currency={orderDetails.currency}
                                    />
                                </div>
                            </div>
                        ) : null}

                        {!orderDetails?.prePaidOrder ? (
                            (orderDetails &&
                                orderDetails?.transactions &&
                                orderDetails?.transactions[0]
                                    ?.paymentGateway === "stripe") ||
                            (orderDetails?.transactions &&
                                orderDetails?.transactions.length &&
                                orderDetails?.transactions[0]
                                    ?.paymentGateway === "adyen" &&
                                orderDetails?.cardData?.cardType !==
                                    undefined) ? (
                                <>
                                    {" "}
                                    <h3 className="pt-10">Paid by</h3>
                                    <div className="pb-10">
                                        {"CARD "}
                                        {orderDetails.cardData
                                            ? "(" +
                                              orderDetails.cardData.cardType.toUpperCase() +
                                              " - " +
                                              orderDetails.cardData
                                                  .lastFourDigit +
                                              (orderDetails.transactions[0]
                                                  .paymentMode &&
                                              orderDetails.transactions[0]
                                                  .paymentMode !== "CARD"
                                                  ? " - " +
                                                    orderDetails.transactions[0]
                                                        .paymentMode
                                                  : "") +
                                              ")"
                                            : ""}
                                    </div>{" "}
                                </>
                            ) : null
                        ) : null}
                        <h3>Your Details</h3>
                        <div className="customer-info">
                            {orderDetails?.user?.firstName}
                            <br />
                            {orderDetails?.user?.email}
                            <br />
                            {orderDetails.formattedContactNumber && (
                                <>
                                    {orderDetails.formattedContactNumber.replace(
                                        "+",
                                        "",
                                    )}
                                    <br />
                                </>
                            )}
                        </div>
                        <h3>Vendor Details</h3>
                        <div className="vendor-info">
                            {orderDetails?.vendor?.displayName}
                            <br />
                            {orderDetails?.vendor?.phone} <br />
                            {orderDetails?.vendor?.address} <br />
                            {orderDetails?.vendor?.postalCode}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps, null)(YourOrder);
